<template>
  <div>
    <div class="row">
      <div class="col mb-3">
        <div class="form-inline justify-content-end">
          <b-form-select
              v-model="analyticsQuery"
              class="analytics-filter"
              size="md"
              :options="['Last 7 Days', 'Last 30 Days', 'Last 90 Days' ,'Lifetime']"
              @change="getAnalytics"
          ></b-form-select>
        </div>
      </div>
    </div>
    <div v-if="user.has_re || user.has_social_review" class="row">
      <div class="col-xl-3 col-lg-6 col-md-6">
        <div class="card">
          <div class="card-body p-2">
            <table v-if="!loadingAnalytics" class="table table-borderless font-size-17">
              <tr>
                <th>Search & Opt-ins</th>
                <th class="text-orange font-size-20 font-weight-bold text-right"></th>
              </tr>
              <tr>
                <td class="pl-4">Prospects</td>
                <td class="text-right text-dark-blue font-weight-bold">{{analytics.search_prospects}}</td>
              </tr>
              <tr>
                <td class="pl-4">Leads</td>
                <td class="text-right text-success font-weight-bold">{{analytics.search_leads}}</td>
              </tr>
            </table>
            <div v-else class="text-center">
              <b-spinner></b-spinner>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-lg-6 col-md-6">
        <div class="card">
          <div class="card-body p-2">
            <table v-if="!loadingAnalytics" class="table table-borderless font-size-17">
              <tr>
                <th>Total Traffic</th>
                <th class="text-orange font-size-20 font-weight-bold text-right">{{analytics.total_traffic}}</th>
              </tr>
              <tr>
                <td class="pl-4">Desktop</td>
                <td class="text-right text-dark-blue font-weight-bold">{{analytics.total_traffic_desktop}}</td>
              </tr>
              <tr>
                <td class="pl-4">Mobile</td>
                <td class="text-right text-success font-weight-bold">{{analytics.total_leads_mobile}}</td>
              </tr>
            </table>
            <div v-else class="text-center">
              <b-spinner></b-spinner>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-lg-6 col-md-6">
        <div class="card">
          <div class="card-body p-2">
            <table v-if="!loadingAnalytics" class="table table-borderless font-size-17">
              <tr>
                <th>Total Leads</th>
                <th class="text-orange font-size-20 font-weight-bold text-right">{{analytics.total_leads}}</th>
              </tr>
              <tr>
                <td class="pl-4">Desktop</td>
                <td class="text-right text-dark-blue font-weight-bold">{{analytics.total_leads_desktop}}</td>
              </tr>
              <tr>
                <td class="pl-4">Mobile</td>
                <td class="text-right text-success font-weight-bold">{{analytics.total_leads_mobile}}</td>
              </tr>
            </table>
            <div v-else class="text-center">
              <b-spinner></b-spinner>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-lg-6 col-md-6">
        <div class="card">
          <div class="card-body p-2">
            <table v-if="!loadingAnalytics" class="table table-borderless font-size-17">
              <tr>
                <th>Conversion Rate</th>
                <th class="text-orange font-size-20 font-weight-bold text-right">{{analytics.conversion_rate}}%</th>
              </tr>
              <tr>
                <td class="pl-4">Desktop</td>
                <td class="text-right text-dark-blue font-weight-bold">{{analytics.conversion_rate_desktop}}%</td>
              </tr>
              <tr>
                <td class="pl-4">Mobile</td>
                <td class="text-right text-success font-weight-bold">{{analytics.conversion_rate_mobile}}%</td>
              </tr>
            </table>
            <div v-else class="text-center">
              <b-spinner></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="row mb-md-2">
          <div class="col-sm-12 col-md-6">
            <div class="form-inline">
              <label>
                Show&nbsp;
              </label>
              <b-form-select
                  v-model="datatable.queries.per_page"
                  class="ml-1 mr-1"
                  size="sm"
                  :options="[10, 15, 25, 50, 100]"
                  @change="getBusinesses"
              ></b-form-select>
              <label class="ml-3">
                Status Filter:&nbsp;
              </label>
              <b-form-select
                  v-model="datatable.queries.prospect_status"
                  class="ml-1 mr-1 status-filter"
                  size="sm"
                  :options="['all','leads','prospect']"
                  @change="getBusinesses"
              ></b-form-select>
            </div>
          </div>
          <div class="col-sm-12 col-md-6">
            <div class="dataTables_filter text-md-right">
              <button type="button" class="btn btn-success btn-sm mr-3" @click="showCreateLeadModal = true">Create Customer <b-spinner v-if="createIsBusy" class="align-middle" small></b-spinner></button>
              <button type="button" class="btn btn-info btn-sm mr-3" @click="showCreateManualLeadModal = true">Create Customer Manually <b-spinner v-if="createIsBusy" class="align-middle" small></b-spinner></button>
              <vue-json-to-csv :json-data="businesses" :csv-title="csvTitle()">
                <button class="btn btn-dark-blue btn-sm mr-3"><Feather class="icon-xs" type="download" /> Export</button>
              </vue-json-to-csv>
              <label class="d-inline-flex align-items-center">
                Search:
                <b-form-input
                    v-model="datatable.queries.search"
                    type="search"
                    placeholder="Search..."
                    class="form-control form-control-sm ml-2"
                ></b-form-input>
              </label>
              <button
                  class="ml-1 btn btn-dark-blue btn-sm"
                  @click="getBusinesses"
              >
                <i class="uil uil-search-alt"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <template v-if="!loadingBusinesses">
              <div class="table-responsive mb-0">
                <b-table
                  :items="businesses"
                  :fields="datatable.columns"
                  :sort-by.sync="datatable.queries.sort_by"
                  :sort-desc.sync="datatable.queries.sort_desc"
                  :per-page="datatable.queries.per_page"
                  data-export-types="['csv']"
                  :no-local-sorting="true"
                  @sort-changed="sortChanged"
                >
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                  </div>
                  <template v-slot:cell(lead_status)="data">
                    <strong class="text-primary" :class="{ 'text-success': data.item.lead_status === 'Lead' }">{{data.item.lead_status}}</strong>
                  </template>
                  <template v-slot:cell(actions)="data">
<!--                    <a :href="data.item.google_maps_url" class="mr-1" target="_blank"><img style="max-height: 30px" src="https://review-link.sfo3.cdn.digitaloceanspaces.com/Icon.png"></a>-->
                    <router-link
                      :to="{
                        name: 'agency.businesses.edit',
                        params: { id: data.item.id },
                      }"
                      class="btn btn-success btn-sm ml-1"
                    >
                      Edit
                    </router-link>
<!--                    <router-link :to="{
                      name: 'agency.businesses.reviews',
                      params: { businessId: data.item.id },
                    }" class="btn btn-info btn-sm ml-1">Reviews</router-link>-->
                    <b-button
                      variant="link"
                      title="Delete"
                      size="lg"
                      class="btn-delete"
                      :disabled="loadingRemove === data.item.id"
                      @click="remove(data.item)"
                    >
                      <b-spinner v-if="loadingRemove === data.item.id" small />
                      <i v-else class="uil uil-trash-alt"></i>
                    </b-button>
                  </template>
                </b-table>
              </div>
              <div class="row mt-4">
                <div class="col">
                  <div class="float-left mt-2">
                    Total {{ totalBusinesses }} businesses
                  </div>
                  <div
                    class="dataTables_paginate paging_simple_numbers float-right"
                  >
                    <ul class="pagination pagination-rounded mb-0">
                      <b-pagination
                        v-model="datatable.queries.page"
                        :total-rows="totalBusinesses"
                        :per-page="datatable.queries.per_page"
                        @change="onPageChanged"
                      ></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </template>
            <div v-else class="text-center">
              <b-spinner></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal v-model="showDeleteModal" title="Delete Business">
      <p v-if="selectedBusiness"
        >Are you sure you want to delete business "{{
          selectedBusiness.name
        }}"?</p
      >
      <template v-slot:modal-footer>
        <b-button variant="light" @click="showDeleteModal = false"
          >Cancel</b-button
        >
        <b-button variant="danger" @click="handleRemove">Delete</b-button>
      </template>
    </b-modal>
    <b-modal v-model="showWelcomeModal" size="lg" title="Welcome">
      <div class="text-center">
        <h4 class="text-success">Thanks For Signing Up For ReplyEngine</h4>
        <p>
        </p>
      </div>
      <template v-slot:modal-footer>
        <b-button variant="success" @click="showWelcomeModal = false"
          >Ok</b-button
        >
      </template>
    </b-modal>
    <b-modal v-model="showCreateLeadModal" size="lg" :hide-footer="true" :hide-header="true" modal-class="widget-modal">
      <div class="text-center">
        <h4>Create Lead</h4>
        <div>
          <reply-engine-generator
              v-if="user && user.agency"
              ref="rlg"
              :search-text="widgetDefaultText"
              class="widget-creator"
              :agency-id="user.agency.id"
              :starting-step="1"
              header-text="Search For Your Business"
              background-color="#ffffff"
              main-text-color="#222222"
              primary-button-background-color="#34a853"
              primary-button-text-color="#ffffff"
              :google-api-key="user.agency.landing_page.admin_settings.google_api_key"
              place-holder="Search"
              :manually-created="true"
              button-text="Create Lead"
              :force-busy="createIsBusy"
              :is-reply-engine="isReplyEngine"
              :is-tap-engine="isTapEngine"
              :is-social-review="isSocialReviews"
              :is-social-fusion="isSocialFusion"
              :is-instagram-widget="isInstaStream"
              :is-review-surge="isReviewSurge"
              @prospectCreated="prospectCreated"
          ></reply-engine-generator>
          <div v-if="user.has_re || user.has_te" style="margin-top: -50px">
            <div v-if="user.has_re" class="d-inline-block mr-3 mb-3">
              <small class="mb-1 font-weight-bold d-block text-right" style="padding-right: 25px">
                <span v-if="isReplyEngine">Enabled</span>
                <span v-else>Disabled</span>
              </small>
              <div class="d-inline-flex">
                <span class="mr-2 d-flex font-weight-bold">ReplyEngine</span>
                <b-form-checkbox
                    v-model="isReplyEngine"
                    size="sm"
                    name="initial-button"
                    switch
                    inline
                >
                </b-form-checkbox>
              </div>
            </div>
            <div v-if="user.has_te" class="d-inline-block mr-3 mb-3">
              <small class="mb-1 font-weight-bold d-block text-right" style="padding-right: 25px">
                <span v-if="isTapEngine">Enabled</span>
                <span v-else>Disabled</span>
              </small>
              <div class="d-inline-flex">
                <span class="mr-2 d-flex font-weight-bold">TapEngine</span>
                <b-form-checkbox
                    v-model="isTapEngine"
                    size="sm"
                    name="initial-button"
                    switch
                    inline
                >
                </b-form-checkbox>
              </div>
            </div>
            <div v-if="user.has_social_review" class="d-inline-block mr-3 mb-3">
              <small class="mb-1 font-weight-bold d-block text-right" style="padding-right: 25px">
                <span v-if="isSocialReviews">Enabled</span>
                <span v-else>Disabled</span>
              </small>
              <div class="d-inline-flex">
                <span class="mr-2 d-flex font-weight-bold">SocialReviews</span>
                <b-form-checkbox
                    v-model="isSocialReviews"
                    size="sm"
                    name="initial-button"
                    switch
                    inline
                >
                </b-form-checkbox>
              </div>
            </div>
            <div v-if="user.has_social_fusion" class="d-inline-block mr-3 mb-3">
              <small class="mb-1 font-weight-bold d-block text-right" style="padding-right: 25px">
                <span v-if="isSocialFusion">Enabled</span>
                <span v-else>Disabled</span>
              </small>
              <div class="d-inline-flex">
                <span class="mr-2 d-flex font-weight-bold">SocialFusion</span>
                <b-form-checkbox
                    v-model="isSocialFusion"
                    size="sm"
                    name="initial-button"
                    switch
                    inline
                >
                </b-form-checkbox>
              </div>
            </div>
            <div v-if="user.has_review_requester" class="d-inline-block mr-3 mb-3">
              <small class="mb-1 font-weight-bold d-block text-right" style="padding-right: 25px">
                <span v-if="isReviewSurge">Active</span>
                <span v-else>Disabled</span>
              </small>
              <div class="d-inline-flex">
                <span class="mr-2 d-flex font-weight-bold">ReviewSurge</span>
                <b-form-checkbox
                    v-model="isReviewSurge"
                    size="sm"
                    name="initial-button"
                    switch
                    inline
                >
                </b-form-checkbox>
              </div>
            </div>
            <div v-if="user.has_insta_stream" class="d-inline-block mr-3 mb-3">
              <small class="mb-1 font-weight-bold d-block text-right" style="padding-right: 25px">
                <span v-if="isInstaStream">Enabled</span>
                <span v-else>Disabled</span>
              </small>
              <div class="d-inline-flex">
                <span class="mr-2 d-flex font-weight-bold">InstaStream</span>
                <b-form-checkbox
                    v-model="isInstaStream"
                    size="sm"
                    name="initial-button"
                    switch
                    inline
                >
                </b-form-checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal v-model="showCreateManualLeadModal" size="lg" :hide-footer="true" title="Create Customer" modal-class="widget-modal">
      <CustomerCreateForm @close="showCreateManualLeadModal = false" />
    </b-modal>
  </div>
</template>


<script>
import VueJsonToCsv from 'vue-json-to-csv'
import ReplyEngineGenerator from '../../../../embed-form/src/components/Form'
import CustomerCreateForm from '@components/Common/CreateCustomer';
export default {
  components:{
    VueJsonToCsv,
    CustomerCreateForm,
    'reply-engine-generator': ReplyEngineGenerator
  },
  data() {
    return {
      analyticsQuery: 'Last 7 Days',
      json_export_labels:{

      },
      loadingAnalytics:false,
      analytics:{
        conversion_rate: '',
        conversion_rate_desktop: '',
        conversion_rate_mobile: '',
        search_leads: '',
        search_prospects: '',
        total_leads: '',
        total_leads_desktop: '',
        total_leads_mobile: '',
        total_traffic: '',
        total_traffic_desktop: '',
        total_traffic_mobile: '',
      },
      datatable: {
        items: [],
        totalItems: 1,
        columns: [
          { key: 'id', label: '#', sortable: true },
          { key: 'small_date', label: 'Date', sortable: true },
          { key: 'business_name', label: 'Business', sortable: true },
          { key: 'full_name', sortable: true },
          { key: 'email', label: 'Email', sortable: true },
          { key: 'phone_number', label: 'Phone', sortable: true },
          { key: 'lead_status', label: 'Status'},
          { key: 'actions', label: 'Actions', class:'actions' },
        ],
        queries: {
          per_page: 10,
          page: 1,
          status: 'active',
          prospect_status:'all',
          search: null,
          sort_by: 'id',
          sort_desc: true,
        },
      },
      showDeleteModal: false,
      selectedBusiness: null,
      loadingBusinesses: false,
      loadingImpersonate: false,
      loadingRemove: null,
      showWelcomeModal: false,
      createIsBusy:false,
      widgetDefaultText:'',
      showCreateLeadModal:false,
      showCreateManualLeadModal:false,
      isReplyEngine:false,
      isTapEngine:false,
      isSocialReviews:false,
      isSocialFusion:false,
      isReviewSurge:false,
      isInstaStream: false,
    }
  },

  computed: {
    businesses() {
      return this.$store.getters['business/all'] || []
    },

    totalBusinesses() {
      return this.$store.getters['business/total']
    },

    reload() {
      return this.$store.getters['business/reload']
    },

    user() {
      return this.$store.getters['auth/user']
    },
  },

  watch: {
    reload: (val) => {
      console.log(val)
    },
  },

  created() {
    this.$store.dispatch('business/setCurrentPage', 'index')
  },

  mounted() {
    this.getBusinesses();
    this.getAnalytics();
    console.log(this.user)
    if(this.user.has_te){
      this.isTapEngine = true;
    }
    if(this.user.has_re){
      this.isReplyEngine = true;
    }
    if(this.user.has_social_review){
      this.isSocialReviews = true;
    }
    if(this.user.has_social_fusion){
      this.isSocialFusion = true;
    }
    if(this.user.has_review_requester){
      this.isReviewSurge = true;
    }
    if(this.user.has_insta_stream){
      this.isInstaStream = true;
    }
    if (this.user.agency.is_new) {
      this.showWelcomeModal = true

      this.$store.dispatch('agency/toggleIsNew', this.user.agency)
    }

    if(window.location.search.includes('create_lead')){
      this.showCreateLeadModal = true;
      let query = Object.assign({}, this.$route.query);
      delete query.create_lead;
      if(query.hasOwnProperty('business_name')) {
        this.widgetDefaultText = decodeURI(query.business_name);
        delete query.business_name;
      }
      this.$router.replace({ query });
    }

    if(window.location.search.includes('create_customer')){
      this.showCreateManualLeadModal = true;
      let query = Object.assign({}, this.$route.query);
      delete query.create_customer;
      this.$router.replace({ query });
    }

  },

  methods: {
    getAnalytics() {
      this.loadingAnalytics = true
      this.$store
          .dispatch('agency/getAnalytics', {
            query: this.analyticsQuery,
            agencyId: this.user.agency.id
          })
          .then((res) => {
            if(res.hasOwnProperty('search_prospects')) {
              this.analytics = res
            }
            this.loadingAnalytics = false
          })
          .catch(() => {
            this.loadingAnalytics = false
          })
    },
    prospectCreated(business){
      this.showCreateLeadModal = false;
      this.createIsBusy = false;
      this.$router.push({
        name: 'agency.businesses.edit',
        params: { id: business.id },
      })
    },
    csvTitle(){
      return 'reply-engine-export-'+Date.now()
    },
    sortChanged(args) {
      this.datatable.queries.sort_by = args.sortBy
      this.datatable.queries.sort_desc = args.sortDesc
      this.datatable.queries.page = 1
      this.getBusinesses()
    },

    onPageChanged(page) {
      this.datatable.queries.page = page
      this.getBusinesses()
    },

    getBusinesses() {
      this.loadingBusinesses = true

      this.$store
        .dispatch('business/getAll', {
          queries: this.datatable.queries,
          page: 'index',
        })
        .then((res) => {
          this.loadingBusinesses = false
        })
        .catch(() => {
          this.loadingBusinesses = false
        })
    },

    impersonate(userId) {
      this.loadingImpersonate = true

      this.$store
        .dispatch('auth/impersonate', userId)
        .then((res) => {
          this.$store
            .dispatch('auth/getProfile')
            .then((user) => {
              this.$router.push('/' + user.role.toLocaleLowerCase())
              this.loadingImpersonate = false
            })
            .catch(() => {
              this.loadingImpersonate = false
            })
        })
        .catch(() => {
          this.loadingImpersonate = false
        })
    },

    remove(business) {
      this.showDeleteModal = true
      this.selectedBusiness = business
    },

    handleRemove() {
      this.showDeleteModal = false
      this.loadingRemove = this.selectedBusiness.id
      this.$store
        .dispatch('business/archive', this.selectedBusiness.id)
        .then(() => {
          this.loadingRemove = null
          this.getBusinesses()
        })
        .catch(() => {
          this.loadingRemove = null
        })
    },
  },
}
</script>
<style lang="scss" scoped>
  .status-filter{
    text-transform: capitalize;
  }
</style>
<style>
.pac-container.pac-logo {
  z-index: 10000000;
}
.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before{
  background: #ccc;
}
.custom-switch .custom-control-label::after{
  background: #efefef;
}
</style>
